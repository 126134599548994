import styles from './ArticleStock.module.scss'
import React, { PropsWithChildren, useState } from 'react'
import { ReactComponent as InformationIcon } from 'assets/icon/designsystem/info.svg'
import { ButtonBase, ClickAwayListener, SvgIcon, Tooltip } from '@mui/material'
import {
  ProductStockStatus,
  ProductType,
  Stock,
  StockType,
} from '@obeta/models/lib/models/Article/Shop/Product'
import { StockAvailabilityEstimate } from '@obeta/schema'
import { Trans, useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { getStockAvailabilityEstimateString } from '@obeta/utils/lib/stock-availability-estimate/getStockAvailabilityEstimateString'

interface Props {
  amount: number
  stockType: StockType
  availableStock: Stock | undefined
  storeAddress: string
  type: string
  status: ProductStockStatus
  available: boolean
  stockAvailabilityEstimate?: StockAvailabilityEstimate | null
}

export const ArticleStockTooltip: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    amount,
    stockType,
    availableStock,
    storeAddress,
    type,
    status,
    available,
    stockAvailabilityEstimate,
  } = props
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  let infoTextToDisplay: JSX.Element | null = null

  // 1. artikel nicht online verfügbar
  if (!available) {
    infoTextToDisplay = (
      <>
        {t('SHOPPING_CART.STOCK.STORE_PICK_UP_ONLY.0')}
        <strong className={styles.red}>{t('SHOPPING_CART.STOCK.STORE_PICK_UP_ONLY.1')}</strong>
        {t('SHOPPING_CART.STOCK.STORE_PICK_UP_ONLY.2')}
      </>
    )
  } else {
    switch (status) {
      // 2. artikel ist lagernd und bestellbar
      case 'partially-available': {
        // 5. lagerartikel online teilweise verfügbar
        if (stockType === 'online') {
          infoTextToDisplay = (
            <>
              {t('SHOPPING_CART.STOCK.DELIVERY_PARTIAL.0')}
              <strong className={styles.orange}>
                {t('SHOPPING_CART.STOCK.DELIVERY_PARTIAL.1', {
                  availableAmount: availableStock?.amount,
                  requestedAmount: amount,
                  unit: availableStock?.unit,
                })}
              </strong>
              {t('SHOPPING_CART.STOCK.DELIVERY_PARTIAL.2') + ' '}
            </>
          )
        } else {
          // 6. lagerartikel in filiale teilweise verfügbar
          // normal article for store
          infoTextToDisplay = (
            <Trans
              values={{
                storeName: storeAddress,
                availableStock: availableStock?.amount,
                amount: amount,
                unit: availableStock?.unit,
              }}
              i18nKey={'SHOPPING_CART.STOCK.PICK_UP_PARTIAL_STOCK'}
              t={t}
              components={[<b key="trans-bold" className={styles.orange} />]}
            />
          )
        }
        break
      }
      case 'currently-not-available':
      case 'not-available': {
        // 3. sonderbestellungsartikel oder diverser artikel online (teilweise) verfügbar - bestand wird 'versteckt'
        // 4. sonderbestellungsartikel oder diverser artikel in filiale teilweise verfügbar - bestand wird 'versteckt'
        // 7. sonderbestellungsartikel (wirklich) kein bestand
        if (
          type === ProductType.special ||
          type === ProductType.custom ||
          type === ProductType.oversize
        ) {
          infoTextToDisplay =
            stockAvailabilityEstimate && !stockAvailabilityEstimate.error ? (
              <>
                {t('SHOPPING_CART.STOCK.SPECIAL_ORDER_PREDICTED_DELIVERY_DATE.0')}
                <strong className={styles.orange}>
                  {t('SHOPPING_CART.STOCK.SPECIAL_ORDER_PREDICTED_DELIVERY_DATE.1')}
                </strong>
                {t('SHOPPING_CART.STOCK.SPECIAL_ORDER_PREDICTED_DELIVERY_DATE.2')}
                <strong className={styles.orange}>
                  {t('SHOPPING_CART.STOCK.SPECIAL_ORDER_PREDICTED_DELIVERY_DATE.3', {
                    stockAvailabilityEstimateString:
                      getStockAvailabilityEstimateString(stockAvailabilityEstimate),
                  })}
                </strong>
                {t('SHOPPING_CART.STOCK.SPECIAL_ORDER_PREDICTED_DELIVERY_DATE.4')}
              </>
            ) : (
              <>
                {t('SHOPPING_CART.STOCK.SPECIAL_ORDER_EXTRA_COSTS.0')}
                <strong>{t('SHOPPING_CART.STOCK.SPECIAL_ORDER_EXTRA_COSTS.1')}</strong>
                {t('SHOPPING_CART.STOCK.SPECIAL_ORDER_EXTRA_COSTS.2')}
              </>
            )
          break
        }

        // online - kein bestand
        if (stockType === 'online') {
          infoTextToDisplay =
            stockAvailabilityEstimate && !stockAvailabilityEstimate.error ? (
              <>
                {t('SHOPPING_CART.STOCK.PREDICTED_DELIVERY_DATE.0')}
                <strong>
                  {t('SHOPPING_CART.STOCK.PREDICTED_DELIVERY_DATE.1', {
                    stockAvailabilityEstimateString:
                      getStockAvailabilityEstimateString(stockAvailabilityEstimate),
                  })}
                </strong>
                {t('SHOPPING_CART.STOCK.PREDICTED_DELIVERY_DATE.2')}
              </>
            ) : (
              t('SHOPPING_CART.STOCK.DELIVERY_OUT_OF_STOCK')
            )
        } else {
          // 9. lagerartikel filiale kein bestand
          infoTextToDisplay = t('SHOPPING_CART.STOCK.PICK_UP_OUT_OF_STOCK', {
            storeName: storeAddress,
          })
        }
        break
      }
    }
  }
  if (desktop) {
    return (
      <>
        {props.children}
        {infoTextToDisplay !== null && (
          <Tooltip classes={{ tooltipArrow: styles.tooltip }} title={infoTextToDisplay}>
            <Stack direction={'row'} alignItems={'center'} display={'inline-flex'}>
              <SvgIcon
                sx={{ '&.MuiSvgIcon-fontSizeMedium': { fontSize: '0.875rem' }, ml: 0.25 }}
                component={InformationIcon}
              />
            </Stack>
          </Tooltip>
        )}
      </>
    )
  }

  return infoTextToDisplay !== null ? (
    <ClickAwayListener
      onClickAway={() => {
        setTooltipOpen(false)
      }}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <Tooltip
        open={tooltipOpen}
        classes={{ tooltipArrow: styles.tooltip }}
        title={infoTextToDisplay}
      >
        <Stack direction={'row'} alignItems={'center'} display={'inline-flex'}>
          <ButtonBase
            className={styles.controlledTooltipTrigger}
            onClick={(e) => {
              e.stopPropagation() //the click should not fall through to the card
              setTooltipOpen(!tooltipOpen)
            }}
          >
            {props.children}
          </ButtonBase>
        </Stack>
      </Tooltip>
    </ClickAwayListener>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{props.children}</>
  )
}
