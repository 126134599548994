import { useState, useCallback, useRef, useEffect } from 'react'

/**
 * Custom hook that allows setting state with a callback function,
 * which will be executed after the state is updated. This mimics
 * the behavior of `setState` in class components where you can pass
 * a callback to be called after the state change is committed.
 *
 * @param initialState - The initial state value.
 * @returns A tuple where the first element is the current state,
 *          and the second is a function to update the state with an optional
 *          callback that will be executed after the state update.
 */
type StateSetter<T> = (
  newState: T | ((prevState: T) => T),
  cb?: (state: T) => void,
  withForceUpdate?: boolean
) => void

function useStateWithCallbackOnUpdate<T>(initialState: T): [T, StateSetter<T>] {
  const [state, setState] = useState<T>(initialState)
  const cbRef = useRef<((state: T) => void) | null>(null)
  const [forceUpdate, setForceUpdate] = useState(new Date())

  const updateState = useCallback<StateSetter<T>>((newState, cb, withForceUpdate = true) => {
    if (cb) cbRef.current = cb

    setState((prev) => {
      const finalState =
        typeof newState === 'function' ? (newState as (prevState: T) => T)(prev) : newState
      return finalState
    })

    // Used in case the callback needs to be called even if the value hasn't changed.
    // For example, we want to add a product with the same quantity and trigger the callback after that
    if (withForceUpdate) setForceUpdate(new Date())
  }, [])

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state)
      cbRef.current = null
    }
  }, [state, forceUpdate])

  return [state, updateState]
}

export default useStateWithCallbackOnUpdate
