import { SplitButton } from '../split-button/SplitButton'
import {
  addProductToCartGraphQL,
  AddCartContextEnum,
  CartsActionTypes,
  ProductItem,
  AddProductToCartGraphQLActionResult,
  AddOrReplaceOfferInCartGraphQLResultAction,
} from '@obeta/data/lib/actions/cart-actions'

import { useActionNotification } from '@obeta/data/lib/hooks/useActionNotification'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { ProductAggregate } from '@obeta/models/lib/models/Article/Shop/Product'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { DropdownCartsBase } from '../dropdown-carts/DropdownCarts'
import { useSelectedCart } from '@obeta/data/lib/hooks/useSelectedCart'
import { useArticlesSearchProvider } from '@obeta/data/lib/hooks/useArticleSearchProvider'
import { sendClickedObjectIdAfterConversionEvent } from '@obeta/utils/lib/algoliaClickEvents'
import { ElbridgeProductItem } from '@obeta/models/lib/models/Elbridge/ElbridgeProductItem'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import {
  MaybeCompleteCartTemplateDetailsItemProduct,
  MaybeCompleteSearchProduct,
} from '@obeta/models/lib/schema-models'
import { updateUserSettings } from '@obeta/data/lib/actions/customer-actions'
import { RecommendationProduct } from '@obeta/models/lib/schema-models/recommendation'
import { useProductCollectionActions } from '@obeta/data/lib/hooks/useProductCollectionActions'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'
import { Product } from '@obeta/models/lib/models/FastProductEntry/Product'
import { FastProductEntryPageItem } from '@obeta/models/lib/schema-models/fast-product-entry'
import { PrimaryButton } from '../custom-button/CustomButton'
import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/add_shopping_cart.svg'
import { AnimatedCartButton } from './AnimatedCartButton'
import { EButtonState, useAnimatedButtonState } from './AnimatedCartButtonContext'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

interface AddItemsToCartButtonProps {
  mobile: boolean
  productAmount: number
  product:
    | ProductAggregate
    | ElbridgeProductItem[]
    | FastProductEntryPageItem[]
    | Product[]
    | MaybeCompleteCartTemplateDetailsItemProduct
    | MaybeCompleteSearchProduct
    | RecommendationProduct
    | undefined
    | null
  carts: ShoppingCartV2[]
  selectedCart: ShoppingCartV2 | undefined
  updateCart?: (cart: ShoppingCartV2, amount?: number) => void
  updateWaitingAddItems?: boolean
  algoliaUserToken?: string
  size?: 'small' | 'large'
  withoutTitle?: boolean
  disableStandardAdd?: boolean
  disabled?: boolean
  onAddToCartClick?: (id: string) => void
  actionType?: CartsActionTypes
}

export const AddItemsToCartButton = React.forwardRef<HTMLDivElement, AddItemsToCartButtonProps>(
  (props, ref) => {
    const {
      mobile,
      productAmount,
      product,
      carts,
      selectedCart,
      updateCart,
      updateWaitingAddItems = false,
      actionType = CartsActionTypes.AddProductToCartGraphQLResult,
      algoliaUserToken,
      size,
      withoutTitle,
      disableStandardAdd,
      onAddToCartClick,
      ...rest
    } = props

    const isAddToCartProcessIndicator = useFeatureToggle('useAddToCartProcessIndicator')
    const splitButtonRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const popoverState = usePopoverState(splitButtonRef)
    const [waitingAddItemsResult, setWaitingAddItemsResult] = useState(false)

    const { animationState, setAnimationState: setButtonState } = useAnimatedButtonState()

    const waitForActionOld = useActionNotification(CartsActionTypes.AddProductToCartGraphQLResult)
    const waitForAction = useActionNotification(actionType)
    const { queryId } = useArticlesSearchProvider()
    const userV2 = useUserV2()
    const { onAddToCart } = useProductCollectionActions()
    const { getSessionCartName, isSessionCart } = useSessionCartName()

    useEffect(() => {
      if (isAddToCartProcessIndicator) return
      setWaitingAddItemsResult(updateWaitingAddItems)
    }, [isAddToCartProcessIndicator, updateWaitingAddItems])

    const addItemsToCart = useCallback(
      (cart: ShoppingCartV2) => {
        if (disableStandardAdd) {
          if (isAddToCartProcessIndicator) {
            setButtonState(EButtonState.Loading)
          }
          updateCart?.(cart, productAmount)
          if (isAddToCartProcessIndicator) {
            waitForAction(
              (
                result:
                  | AddOrReplaceOfferInCartGraphQLResultAction
                  | AddProductToCartGraphQLActionResult
              ) => {
                let isSuccess: boolean
                if (result.type === CartsActionTypes.AddProductToCartGraphQLResult) {
                  isSuccess = !!result.payload?.success
                } else {
                  isSuccess = result.success
                }

                setButtonState(isSuccess ? EButtonState.Success : EButtonState.Error)
              }
            )
          }
          return
        }
        if (!product) {
          console.warn('Add item to cart: no product to add it to cart!')
          return
        }

        if (algoliaUserToken) {
          if (!(product instanceof Array)) {
            sendClickedObjectIdAfterConversionEvent(algoliaUserToken, queryId, product.sapId)
          }
        }

        if (isAddToCartProcessIndicator) {
          setButtonState(EButtonState.Loading)
        } else {
          setWaitingAddItemsResult(true)
        }
        let items: ProductItem[] = []
        if (product instanceof Array) {
          product.forEach(
            (productItem: ElbridgeProductItem | FastProductEntryPageItem | Product) => {
              items.push({
                sapId: productItem.product.sapId,
                amount: productItem.amount,
                title: productItem.product.title,
              })
            }
          )
        } else {
          items = [{ sapId: product.sapId, amount: productAmount, title: product.title }]
        }

        dispatch(
          addProductToCartGraphQL({
            cartId: cart.id,
            items: items,
            context: AddCartContextEnum.Generic,
          })
        )
        updateCart?.(cart)
        onAddToCart('sapId' in product ? product.sapId : '')
        if (isAddToCartProcessIndicator) {
          waitForAction((result: AddProductToCartGraphQLActionResult) => {
            setButtonState(result.payload?.success ? EButtonState.Success : EButtonState.Error)
          })
        } else {
          waitForActionOld(() => {
            setWaitingAddItemsResult(false)
          })
        }
        // Set the last used cart as the 'active' cart
        if (userV2?.settings?.defaultCartId !== cart.id) {
          dispatch(
            updateUserSettings({
              defaultCartId: cart.id,
            })
          )
        }
      },
      [
        disableStandardAdd,
        product,
        algoliaUserToken,
        isAddToCartProcessIndicator,
        dispatch,
        updateCart,
        onAddToCart,
        productAmount,
        setButtonState,
        waitForAction,
        queryId,
        waitForActionOld,
        userV2?.settings?.defaultCartId,
      ]
    )

    const onClick = useCallback(() => {
      if (!selectedCart) {
        return
      }

      onAddToCartClick && onAddToCartClick(selectedCart.id)

      addItemsToCart(selectedCart)
    }, [selectedCart, addItemsToCart, onAddToCartClick])

    let cartName = '-'
    if (selectedCart) {
      cartName = isSessionCart(selectedCart) ? getSessionCartName(selectedCart) : selectedCart.name
    }

    return (
      <div ref={ref}>
        {isAddToCartProcessIndicator ? (
          <AnimatedCartButton
            ref={splitButtonRef}
            cartsLength={carts.length}
            title={cartName}
            buttonState={animationState}
            onArrowDownClicked={popoverState.handleClick}
            onClick={onClick}
            size={size}
            withoutTitle={withoutTitle}
            {...rest}
          />
        ) : carts.length > 1 ? (
          <SplitButton
            ref={splitButtonRef}
            title={cartName}
            loading={waitingAddItemsResult}
            onArrowDownClicked={popoverState.handleClick}
            onClick={onClick}
            size={size}
            withoutTitle={withoutTitle}
            {...rest}
          />
        ) : (
          <PrimaryButton leftIcon={<AddShoppingCartIcon />} size={size} onClick={onClick}>
            {cartName}
          </PrimaryButton>
        )}
        <div
          onClick={(e) => {
            /* ToDo: fix this
          the popover reinjects the click event to its parent, we dont want this as the click is handled in the component already
          exampl: disable this line, open the shoppingcart switch in resultitems, try to close it -> the product detail page will be loaded
          */

            e.stopPropagation()
          }}
        >
          <DropdownCartsBase
            carts={carts.map((cart) => {
              const cartName = isSessionCart(cart) ? getSessionCartName(cart) : cart.name
              return { ...cart, name: cartName, count: cart.items.length }
            })}
            mobile={mobile}
            onOptionSelected={(cart) => {
              addItemsToCart(cart)
            }}
            dropdown={{
              anchorEl: mobile ? null : popoverState.anchorEl,
              onClose: popoverState.onClose,
              open: popoverState.open,
            }}
          />
        </div>
      </div>
    )
  }
)

export const AddItemsToCartButtonWithCarts = React.forwardRef<
  HTMLDivElement,
  Omit<AddItemsToCartButtonProps, 'carts' | 'selectedCart'>
>((props, ref) => {
  const carts = useEntities<ShoppingCartV2>('cartsv2')
  const selectedCart = useSelectedCart()
  return <AddItemsToCartButton ref={ref} {...props} carts={carts} selectedCart={selectedCart} />
})
