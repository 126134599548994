import {
  ProductStockStatus,
  ProductType,
  StockType,
} from '@obeta/models/lib/models/Article/Shop/Product'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getProductStockStatus, StockAmount } from '@obeta/utils/lib/productStockHelpers'
import { getStockAvailabilityEstimateString } from '@obeta/utils/lib/stock-availability-estimate/getStockAvailabilityEstimateString'
import { normalizeNumber } from '@obeta/utils/lib/data-formatter/normalizeNumber'
import { StockAvailabilityEstimate } from '@obeta/schema'

export const useProductStockStatusColor = (
  stockStatus: ProductStockStatus,
  productType: ProductType
) => {
  const theme = useTheme()

  switch (stockStatus) {
    case 'available': {
      // green
      return theme.palette.success.main
    }

    case 'partially-available': {
      // orange
      return theme.palette.warning.main
    }

    case 'currently-not-available': {
      // orange
      if (
        productType === ProductType.special ||
        productType === ProductType.custom ||
        productType === ProductType.oversize
      ) {
        return theme.palette.warning.main
      }

      return theme.palette.secondary.dark //grey
    }

    case 'not-available': {
      // grey
      return theme.palette.primary.main
    }

    case 'not-for-sale': {
      return theme.palette.grayVariant.dark34K
    }

    default: {
      const unrecognizedStatus: never = stockStatus
      throw new Error(`${unrecognizedStatus} is not mapped to color`)
    }
  }
}

export const useProductStockStatusDescription = (
  data: {
    stockStatus: ProductStockStatus
    productType: ProductType
    stockType: StockType
    stockAmount: StockAmount
    amount: number
    stockAvailabilityEstimate?: StockAvailabilityEstimate
  } | null
) => {
  const { t } = useTranslation()
  if (!data) {
    return null
  }

  const { stockAmount: _stockAmount, stockStatus, productType, stockType, amount: _amount } = data
  const amount = normalizeNumber(_amount, 0)
  const stockAmount = normalizeNumber(_stockAmount || 0, 0)

  switch (stockStatus) {
    // 1. artikel nicht online verfügbar
    case 'not-available': {
      return t('SHOPPING_CART.STOCK.NOT_AVAILABLE')
    }
    // 2. artikel ist lagernd und bestellbar
    case 'partially-available': {
      if (
        productType === ProductType.special ||
        productType === ProductType.custom ||
        productType === ProductType.oversize
      ) {
        return String(stockAmount) + ' / ' + t('SHOPPING_CART.STOCK.SPECIAL_ORDER')
      }
      return String(stockAmount) + ' / ' + amount
    }
    case 'currently-not-available': {
      // 7. sonderbestellungsartikel kein bestand
      if (
        productType === ProductType.special ||
        productType === ProductType.custom ||
        productType === ProductType.oversize
      ) {
        if (data?.stockAvailabilityEstimate && !data?.stockAvailabilityEstimate.error) {
          return `ca. ${getStockAvailabilityEstimateString(data?.stockAvailabilityEstimate)}`
        } else return t('SHOPPING_CART.STOCK.SPECIAL_ORDER')
      }
      if (
        stockType === 'online' &&
        productType === ProductType.normal &&
        data?.stockAvailabilityEstimate &&
        !data?.stockAvailabilityEstimate.error
      ) {
        return `ca. ${getStockAvailabilityEstimateString(data?.stockAvailabilityEstimate)}`
      }
      return t('SHOPPING_CART.STOCK.OUT_OF_STOCK')
    }
  }

  return null
}

export const useProductStockData = (data: {
  productType: ProductType
  stockType: StockType
  stockAmount: number
  amount: number
  available: boolean
}) => {
  const { stockAmount, productType, amount, available, stockType } = data
  const isHiddenAtpStockForSpecialOrCustomProduct =
    (productType === ProductType.special && available) || productType === ProductType.custom

  let stockValue
  if (isHiddenAtpStockForSpecialOrCustomProduct) {
    stockValue = 0
  } else {
    stockValue = available ? stockAmount : null
  }

  const status = getProductStockStatus(stockValue, amount)
  const statusColor = useProductStockStatusColor(status, productType)
  const statusDescription = useProductStockStatusDescription({
    stockStatus: status,
    productType,
    stockType,
    stockAmount,
    amount,
  })

  return { status, statusColor, statusDescription }
}
